export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsData = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Profit",
    accessor: "profit",
  },
  {
    Header: "Profit %",
    accessor: "profit_percentage",
  },
  {
    Header: "Deposit",
    accessor: "deposit",
  },
  {
    Header: "Cycles Completed",
    accessor: "cycles_completed",
  },
];

export const volatilityData = [
  {
    Header: "Position №",
    accessor: "position",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Quantity",
    accessor: "volatility",
  },
];
